/* eslint-disable @typescript-eslint/no-explicit-any */
import { getTxCount, getTxList } from "@/service/api";
import { filterNum } from "@/utils/utils";
import { icustomized2Tx } from "@/types/trade";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      searchValue: "",
      pages: {
        currentPage: 1,
        pageSize: 20,
        total: 10,
      },
      total: 0,
      txList: [] as icustomized2Tx[],
      loading: false,
      Timer: 0,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    filterNum,
    checkGroup(arr: icustomized2Tx[]) {
      arr.map((current, index) => {
        if (current.group_count > 1) {
          current.tradeG = 1; //0-非交易组，1-交易组，3-交易组末条, 2-交易组首条
          // current.tradeG = (current.tx.fee === 0 && arr[index-1].tx.fee!==0)? 2 : 1
          if (current.fee !== 0) {
            current.tradeG = 2;
          } else {
            if (index < arr.length) {
              console.log(index);
              current.tradeG = arr[index].next === "" ? 3 : 1;
            } else {
              current.tradeG = 3;
            }
          }
        } else {
          current.tradeG = 0;
        }
      });
      return arr;
    },
    groupCellName(val: any) {
      switch (val.row.tradeG) {
        case 0:
          return "";
        case 1:
          return "tx-middle";
        case 2:
          return "tx-start";
        case 3:
          return "tx-end";
        default:
          return "";
      }
    },
    init() {
      this.loading = true;
      getTxCount().then((ret) => {
        if (!ret.error) {
          this.pages.total = ret.result <= 100000 ? ret.result : 100000;
          this.total = ret.result;
          getTxList(this.pages.currentPage, this.pages.pageSize).then((ret) => {
            if (!ret.error) {
              this.txList = this.checkGroup(ret.result);
              this.loading = false;
              if (this.pages.currentPage === 1) {
                this.Timer = window.setTimeout(() => {
                  this.init();
                }, 10 * 1e3);
              }
            }
          });
        }
      });
    },
    pageChange(page: number) {
      this.pages.currentPage = page;
      this.loading = true;
      window.clearTimeout(this.Timer);
      getTxList(this.pages.currentPage, this.pages.pageSize).then((ret) => {
        if (!ret.error) {
          this.txList = this.checkGroup(ret.result);
          this.loading = false;
          if (this.pages.currentPage === 1) {
            this.Timer = window.setTimeout(() => {
              this.init();
            }, 10 * 1e3);
          }
        }
      });
     
    },
    sizeChange(size: number) {
      this.pages.pageSize = size;
      this.pageChange(1);
    },
  },
  beforeDestroy() {
    window.clearTimeout(this.Timer);
  },
});
