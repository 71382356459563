














































import VueTypedMixins from 'vue-typed-mixins'
import ChainSearch from '@/components/pc/BlockChainBrowser/ChainSearch.vue'
import PageContainer from '@/components/pc/BlockChainBrowser/PageContainer.vue'
import TradeTable2 from '@/components/pc/BlockChainBrowser/TradeTable2.vue'
import arrowRightBlue from '@/assets/images/blockChainBrowser/blockDetail/arrow-right-blue.png'
import arrowRightDark from '@/assets/images/blockChainBrowser/blockDetail/arrow-right-dark.png'
import Count from '@/components/pc/BlockChainBrowser/Count.vue'
import tradeMixin from '@/mixin/trade'
import TxStatus from '@/components/pc/txStatus.vue'
export default VueTypedMixins(tradeMixin).extend({
  components: {
    ChainSearch,
    PageContainer,
    Count,
    TxStatus,
    TradeTable2,
  },
  data() {
    return {
      arrowRightBlue,
      arrowRightDark,
    }
  },
})
