




import filters from '@/utils/filters'
import Vue from 'vue'
export default Vue.extend({
  props: ['time'],
  data() {
    return {
      showTime: 'loading...' as string | number,
      Timer: 0,
    }
  },
  mounted() {
    this.caculateTime()
    if (this.Timer === 0)
      this.Timer = window.setInterval(() => {
        this.caculateTime()
      }, 1000)
  },
  methods: {
    caculateTime() {
      let Dt = new Date()
      let local = Dt.getTime()
      let dValue = parseInt(String(local / 1000 - this.time))
      let h = parseInt(String(dValue / 3600))
      dValue -= h * 3600
      let m = parseInt(String(dValue / 60))
      dValue -= m * 60
      let s = parseInt(String(dValue))
      if (parseInt(String(local / 1000 - this.time)) >= 86400)
        this.showTime = filters.formatTime(this.time)
      else if (parseInt(String(local / 1000 - this.time)) >= 3600)
        this.showTime = `${h}${
          h == 1
            ? this.$t('lang.components.hour')
            : this.$t('lang.components.hours')
        }${m}${
          m == 1
            ? this.$t('lang.components.min')
            : this.$t('lang.components.mins')
        }${s}${
          s == 1
            ? this.$t('lang.components.sec')
            : this.$t('lang.components.secs')
        }`
      else if (parseInt(String(local / 1000 - this.time)) >= 60)
        this.showTime = `${m}${
          m == 1
            ? this.$t('lang.components.min')
            : this.$t('lang.components.mins')
        }${s}${
          s == 1
            ? this.$t('lang.components.sec')
            : this.$t('lang.components.secs')
        }`
      else
        this.showTime = `${s}${
          s == 1
            ? this.$t('lang.components.sec')
            : this.$t('lang.components.secs')
        }`
    },
  },
  beforeDestroy() {
    //清除定时器
    clearTimeout(this.Timer)
  },
})
